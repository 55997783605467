.page {
	@supports(display: grid) {
		ul.-boxes {
			display: grid;
			list-style: none;
			@media (orientation: portrait) {
				grid-template-columns: repeat(2, minmax(50vw, 1fr));
				grid-auto-rows: 50vw;
			}
			@media (orientation: landscape) {
				grid-template-columns: unset;
				grid-template-rows: repeat(2, 50vh);
				grid-auto-columns: 50vh;
				grid-auto-rows: unset;
				grid-auto-flow: column dense;
			}
			@media (orientation: portrait) and (min-width: 768px) {
				grid-template-columns: repeat(3, 1fr);
				grid-auto-rows: 33.33vw;
			}
			@media (orientation: landscape) and (min-height: 768px) {
				grid-template-columns: repeat(3, 33.33vw);
				grid-template-rows: unset;
				grid-auto-columns: unset;
				grid-auto-rows: 33.33vw;
				grid-auto-flow: row dense;
			}
			@media (min-width: 1024px) and (min-height: 768px) {
				grid-template-columns: repeat(4, 1fr);
				grid-auto-rows: 25vw;
			}
			@media (min-width: 1366px) and (min-height: 768px) {
				grid-template-columns: repeat(5, 1fr);
				grid-auto-rows: 20vw;
				font-size: 1.5vw;
			}
			li {
				display: contents;
			}
		}
	}
}
.work {
	display: flex;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	padding: 1.5em 1em;
	flex-direction: column;
	color: white;
	background-image: linear-gradient(to bottom right, var(--accent-color-primary), var(--accent-color-secondary));
	filter: saturate(75%);
	transition-property: filter;
	transition-duration: 0.2s;
	@supports (display: contents) {
		width: unset;
		height: unset;
	}
	.work-title {
		margin-bottom: 0.25em;
		overflow: hidden;
		font: 500 1em/1.2 "Source Serif Pro", serif;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.work-description {
		font-size: 0.75em;
		opacity: 0.75;
		transition-property: opacity;
		transition-duration: 0.2s;
	}
	&:focus,
	&:hover {
		filter: saturate(100%);
		.work-description {
			opacity: 1.00;
		}
	}
	&:active {
		filter: saturate(125%);
	}
	&[data-credit="hype"] {
		background-image: url("../assets/hype-logo.svg"), linear-gradient(to bottom right, var(--accent-color-primary), var(--accent-color-secondary));
		background-position: right 0.7em bottom 0.8em, top left;
		background-repeat: no-repeat;
		background-size: auto 2em, auto;
	}
	&.-featured {
		grid-column: span 2;
	}
}
